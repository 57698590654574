.header {
  width: 100%;
  padding: 0px;
  display: flex;
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  flex-direction: column;
  align-items: center;
}

.header_wrapper {
  width: 100%;
  max-width: 440px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.header_cta_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px 28px;
  flex-grow: 1;
  height: 46px;
  margin-top: 24px;
  max-width: 440px;
}
.header_cta {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px 24px;
  flex-grow: 1;
  height: 100%;
  padding-left: 18px;
  padding-right: 8px;
  background-color: #fff;
  border-radius: 40px;
  font-size: 16px;
  font-weight: 700;
  color: #181818;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}
.header_cta .button {
  background-color: #181818;
  padding: 0px 16px;
  color: #ffffff;
  height: 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
  line-height: 32px;
  font-size: 14px;
  font-weight: 600;
}

.header_wrapper.no_shadow {
  box-shadow: none;
}

.header_logo {
  display: flex;
  align-items: center;
  height: 64px;
  padding-left: 28px;
}

.header_logo_link {
  height: 18px;
}

.header_logo img {
  height: 18px;
}

.hamburger {
  width: 16px;
  height: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  background-color: white;
  margin-right: 33px;
  border: none;
}

.hamburger-line {
  width: 16px;
  height: 2px;
  background-color: black;
}

.hamburger:hover .hamburger-line {
  background-color: #555;
}
.share-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  background-color: #fff;
  margin-right: 28px;
  cursor: pointer;
}
