.form-container {
  padding-top: 60px;
}

.step-form {
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
}

textarea {
  resize: vertical;
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 80px;
}
.form-header.header-11 {
  margin-bottom: 0px;
}
.form-header .back-button {
  padding-left: 25px;
}
