.cta-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 40px;
  background-color: black;
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 25px;
  transition: background-color 0.3s ease;
  width: 120px;
  font-weight: 700;
}
.rotate-180 {
  transform: rotate(180deg);
}

.hero {
  background-color: #181818;
  padding: 130px 0px;
  text-align: center;
  color: #fff;
  height: 510px;
  position: relative;
}

.hero-background {
  height: 474px;
  background-image: url("../../assets/images/bg_hero.png");
  background-size: cover;
  background-position: bottom;
  position: absolute;
  bottom: 0px; /* 이미지가 hero 섹션 바로 아래에 위치하게 함 */
  left: 0;
  right: 0;
}

.hero .container {
  max-width: 600px;
  width: 100%;
}

.hero h1 {
  font-size: 28px;
  line-height: 40px;
  margin-top: 32px;
  margin-bottom: 28px;
  font-family: "NPSfontBold", "League Spartan";
  color: #fff;
  margin-left: 31px;
  text-align: left;
  font-weight: 700;
}

.hero p {
  font-size: 18px;
  color: #fff;
  text-align: left;
  font-weight: 700;
  margin-left: 31px;
  line-height: 28px;
}

section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

section h2 {
  font-size: 24px;
  color: #fff;
}

.section1 {
  height: 660px;
  position: relative;
  padding-top: 68px;
}
.section1 h2 {
  font-size: 24px;
  color: #000;
  text-align: center;
  line-height: 40px;
  margin-bottom: 28px;
}
.section1-background {
  width: 100%;
  height: 500px;
  background-image: url("../../assets/images/bg_section1.png");
  background-size: cover;
  background-position: bottom;
  position: absolute;
  bottom: 0px; /* 이미지가 hero 섹션 바로 아래에 위치하게 함 */
  left: 0;
  right: 0;
}
.section1 p {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  color: #a2a2a2;
  text-align: center;
}
.section2 {
  height: 404px;
  padding-top: 68px;
  background-color: #181818;
  position: relative;
}
.section2 .container {
  z-index: 1;
}
.section2 h2 {
  font-size: 24px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  margin-bottom: 28px;
  z-index: 1;
}
.section2 p {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  color: #fff;
  text-align: center;
}
.section2-background {
  width: 100%;
  height: 452px;
  background-image: url("../../assets/images/bg_section2.png");
  background-size: cover;
  background-position: bottom;
  position: absolute;
  bottom: 0px; /* 이미지가 hero 섹션 바로 아래에 위치하게 함 */
  left: 0;
  right: 0;
}
.section3 {
  height: 610px;
  padding-top: 68px;
  background-color: #8e6dff;
  position: relative;
}
.section3 .container {
  z-index: 1;
  width: 100%;
}
.section3 h2 {
  font-size: 24px;
  color: #fff;
  text-align: left;
  line-height: 40px;
  margin-left: 31px;
  margin-bottom: 28px;
  z-index: 1;
}
.section3 p {
  line-height: 28px;
  color: #fff;
  text-align: left;
  margin-left: 31px;
  font-size: 18px;
  font-weight: 700;
}
.section3-background {
  width: 100%;
  height: 658px;
  background-image: url("../../assets/images/bg_section3.png");
  background-size: cover;
  background-position: bottom;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
}
.section4 {
  height: 350px;
  padding-top: 72px;
  background-color: #181818;
}
.section4 p {
  font-size: 18px;
  font-weight: 700;
}
.section4 h2 {
  font-size: 24px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  margin-bottom: 28px;
  z-index: 1;
}
.section4 .container {
  width: 100%;
}

.section4 .container .intro-text {
  line-height: 28px;
  color: #fff;
  text-align: center;
  margin-bottom: 40px;
}

.section4 .profile-scroll {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
  gap: 10px;
}

.section4 .profile-card {
  display: flex;
  flex-direction: row;
  padding: 17px 23px 17px 20px;
  background: #ffffff;
  color: #181818;
  border-radius: 32px;
  width: 236px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
}

.section4 .profile-scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.section4 .profile-scroll .profile-card:first-child {
  margin-left: 33px;
}
.section4 .profile-scroll .profile-card:last-child {
  margin-right: 33px;
}

.section4 .profile-card img {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-color: #8e6dff;
  margin-right: 18px;
}
.section4 .profile-card .right-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  width: 129px;
  gap: 9px;
}
.section4 .profile-card .right-content h3 {
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  font-weight: 600;
  color: #181818;
}
.section4 .profile-card .right-content p {
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 0px;
  font-weight: 600;
  color: #181818;
}
.section4 .profile-card .right-content .tags {
  display: flex;
  gap: 5px;
}
.section4 .profile-card .right-content .see-more {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  gap: 8px;
  font-family: "Heiti TC", sans-serif;
}
.section4 .profile-card .right-content .see-more p {
  color: #aaaaaa;
  font-weight: 400;
}
.section4 .profile-card .right-content span {
  font-size: 10px;
  line-height: 15px;
  height: 16px;
  padding: 3px 9px 1px;
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
  background-color: #181818;
}

.section5 {
  height: 379px;
}
.section5 .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section5 .title-container {
  display: inline-block;
  position: relative;
}

.section5 h2 {
  font-size: 24px;
  color: #181818;
  text-align: center;
  line-height: 40px;
  padding-top: 72px;
  margin-bottom: 28px;
  z-index: 1;
}
.section5 p {
  line-height: 28px;
  color: #a2a2a2;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 40px;
}
.section5 .star-icon {
  position: absolute;
  top: 36px;
  right: -45px;
  width: 62px;
  height: 62px;
}

.section6 {
  height: 386px;
  background-color: #181818;
}

.section6 h2 {
  font-size: 24px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  padding-top: 67px;
  margin-bottom: 36px;
}

.section6 .image-section6 {
  width: 302px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.profile-popup {
  width: calc(100% - 60px);
  max-height: 90%;
  background-color: none;
  overflow-y: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding: 0px;
  border-radius: 45px;
}

.profile-popup::-webkit-scrollbar {
  display: none; /* 스크롤 바 숨기기 */
}

.profile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.profile-content img.profile-image {
  width: 100%;
  height: 100%;
}

.faq-section {
  background-color: #181818;
  color: #fff;
  padding: 0px 32px;
  -webkit-tap-highlight-color: transparent;
}
.faq-section .container {
  width: 100%;
  padding-top: 4px;
}

.faq-section h2 {
  font-size: 24px;
  color: #fff;
  text-align: center;
  line-height: 48px;
  font-weight: 600;
  padding-top: 62px;
}

.faq-item {
  flex-grow: 1;
  width: 100%;
  border-bottom: 1px solid #454545;
  min-height: 28px;
  padding: 17px 0px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.4s ease;
  overflow: hidden;
}

.faq-item .question {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
}
.faq-item .arrow {
  transition: transform 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.faq-item .arrow.open {
  transform: rotate(180deg);
}

.faq-item .answer {
  margin-top: 10px;
  font-size: 14px;
  line-height: 28px;
  font-weight: 600;
  overflow: hidden;
  transition: max-height 0.4s ease, padding 0.4s ease;
}
