/* App.css */

html,
body,
#root,
.App {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal scroll due to sliding menu */
}

.App {
  padding: 0;
  max-width: 440px;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
}

/* Mobile Styles */
@media (max-width: 600px) {
  .menu-container {
    width: 80%;
    right: 0;
    transition: transform 0.5s ease-in-out;
  }

  .menu-container .close-button {
    font-size: 30px;
  }

  .menu ul li {
    font-size: 20px;
  }
}

/* Desktop Styles */
@media (min-width: 601px) {
  .menu-container {
    width: 100%;
    height: 100%;
    justify-content: center;
  }
}
