/* index.css */

/* 글로벌 스타일 설정 */
body {
  font-family: "Pretendard Variable";
  margin: 0;
  padding: 0;
  background-color: white;
  color: #333; /* 기본 텍스트 색상 */
}

h1 {
  text-align: center;
  color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0px;
  margin-bottom: 0px;
}
label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
}

input,
textarea {
  width: 100%;
  padding: 12px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

textarea {
  resize: vertical;
}

@font-face {
  font-family: "NPSfontBold";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2310@1.0/NPSfontBold.woff2")
    format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Pretendard";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff")
    format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Pretendard";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff")
    format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "Pretendard";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff")
    format("woff");
  font-weight: 600;
}
