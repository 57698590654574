/* StepForm.css */

.step-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  padding: 0px 31px;
  box-sizing: border-box;
}

.back-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.logo {
  height: 40px;
}

.form-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.form-content h3 {
  font-size: 20px;
  margin-bottom: 54px;
  line-height: 40px;
  font-weight: 700;
}
.form-content p {
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 40px;
  font-weight: 600;
  color: #a2a2a2;
}
.step-1 p,
.step-2 p {
  margin-bottom: 11px;
}
.step-1 h3,
.step-2 h3,
.step-3 h3 {
  margin-bottom: 3px;
}
.step-3 p {
  font-size: 14px;
  margin-bottom: 11px;
  line-height: 40px;
  font-weight: 600;
  color: #a2a2a2;
}
.step-4 h3 {
  margin-bottom: 24px;
}

.step-7 h3,
.step-8 h3 {
  margin-bottom: 3px;
}

.step-7 p {
  margin-bottom: 8px;
}

input,
select {
  width: 200px;
  padding: 10px;
  font-size: 20px;
  font-weight: 700;
  border: none;
  border-bottom: 2px solid #e9e9e9;
  text-align: center;
  background: none;
  outline: none;
  border-radius: 0;
}

input::placeholder,
select::placeholder {
  font-size: 20px;
  color: #d2d2d2;
}
textarea::placeholder {
  font-size: 14px;
  color: #d2d2d2;
}
input:focus,
select:focus {
  border: none;
  border-bottom: 2px solid #000; /* 포커스 시에도 밑줄 유지 */
}

.form-footer {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 60px;
}

.form-footer button {
  width: 200px;
  height: 40px;
  font-size: 14px;
  background-color: #1f1f1f;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.step-14-footer-button button {
  width: 96px;
}

.form-footer button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.form-footer p {
  text-decoration: underline;
  font-size: 14px;
  line-height: 40px;
  color: #aeaeae;
}

.job-list {
  max-height: 50vh;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style-type: none;
  text-align: center;
  -ms-overflow-style: none; /* IE 및 Edge */
  scrollbar-width: none; /* Firefox */
}

/* 스크롤 바를 숨기기 위한 CSS */
.job-list::-webkit-scrollbar {
  display: none; /* 크롬, 사파리, 엣지 */
}

.job-item {
  padding: 10px 0;
  font-size: 18px;
  cursor: pointer;
  color: #ccc;
  height: 40px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
}

.job-item:active {
  background-color: transparent; /* 클릭 시 배경색이 변하지 않도록 설정 */
  -webkit-box-shadow: none; /* 웹킷 브라우저에서의 클릭 시 그림자 제거 */
  box-shadow: none; /* 일반 브라우저에서의 클릭 시 그림자 제거 */
}
.job-item.selected {
  color: #000;
  font-weight: bold;
}
.job-item:focus {
  outline: none; /* 포커스 시 생기는 외곽선을 없앰 */
  background-color: transparent; /* 클릭 시 배경색이 변하지 않도록 설정 */
}

.job-item:hover {
  color: #000;
}

.step-7 {
  text-align: center;
}

.step-7 h3 {
  margin-bottom: 3px;
}

.step-7 p {
  color: #a2a2a2;
  margin-bottom: 34px;
  margin-top: 0;
}

.interest-category {
  margin-bottom: 32px;
}

.interest-category h4 {
  margin-bottom: 16px;
  font-weight: bold;
  line-height: 40px;
  margin-top: 0px;
}

.interest-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 2px 10px;
}

.interest-item {
  padding: 0px 10px;
  line-height: 40px;
  border-radius: 15px;
  cursor: pointer;
  font-size: 14px;
  color: #d2d2d2;
  font-weight: 600;
  -webkit-tap-highlight-color: transparent;
  transition: color 0.3s ease;
}
.interest-item:active {
  background-color: transparent; /* 클릭 시 배경색이 변하지 않도록 설정 */
  -webkit-box-shadow: none; /* 웹킷 브라우저에서의 클릭 시 그림자 제거 */
  box-shadow: none; /* 일반 브라우저에서의 클릭 시 그림자 제거 */
}
.interest-item:focus {
  outline: none; /* 포커스 시 생기는 외곽선을 없앰 */
  background-color: transparent; /* 클릭 시 배경색이 변하지 않도록 설정 */
}

.interest-item.selected {
  color: #1f1f1f;
  font-weight: 600;
}

.step-10,
.step-11,
.step-12,
.step-13 {
  width: 100%;
}
.step-10 h3,
.step-11 h3,
.step-12 h3,
.step-13 h3 {
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 4px;
  line-height: 40px;
  font-weight: 700;
  text-align: left;
  width: 100%;
}
.step-10 h3 {
  height: 94px;
  margin-bottom: 22px;
}

.step-11 p,
.step-12 p,
.step-13 p {
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 12px;
  line-height: 40px;
  font-weight: 600;
  text-align: left;
  width: 100%;
}

.step-10 .question-list,
.step-11 .question-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.question-list li {
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-10 .question-item,
.step-11 .question-item {
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  color: #d2d2d2;
  line-height: 40px;
  font-weight: 600;
}

.step-10 .question-item.selected {
  color: #1f1f1f;
  font-weight: 600;
}

.step-11 textarea,
.step-12 textarea,
.step-13 textarea {
  width: 100%;
  height: 185px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 40px;
  border: 1px solid #e9e9e9;
  border-radius: 25px;
  resize: none;
}

.step-11 textarea:focus,
.step-12 textarea:focus,
.step-13 textarea:focus {
  outline: none;
}

.step-11 button {
  width: 100%;
  padding: 15px;
  font-size: 18px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
}
.step-5-footer-button {
  margin-top: 0px;
}
.step-7-footer-button {
  margin-top: 48px;
}

.step-11-footer-button,
.step-12-footer-button,
.step-13-footer-button {
  margin-top: 28px;
}

.step-14 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.step-14 h3 {
  text-align: left;
  width: 100%;
  line-height: 40px;
  margin-bottom: 36px;
  font-weight: 600;
}
.step-14 h3:first-child {
  text-align: left;
  width: 100%;
}
.step-14 .done_card {
  position: relative;
  width: 178px;
  height: 247px;
  background-image: url("../../assets/images/enroll_done_card.png");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 5px 12px 10px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
}
.step-14 .done_card p {
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0px;
  line-height: 36px;
}
.step-14 .done_card .name {
  margin-top: 25px;
}
.step-14 .done_card span {
  font-size: 36px;
  font-family: "Baloo 2", sans-serif;
  margin-right: 4.5px;
  line-height: 46px;
}

.cloud_box {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/images/bg_done_page.png"); /* 이미지 경로를 실제 경로로 수정 */
  background-size: contain;
  background-position: center;
  padding: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 34px;
  width: 287px;
  height: 165px;
  top: 190px;
  margin: 20px auto;
  z-index: 2;
}
.step-14 .cloud_box .box-text {
  margin-top: 0px;
}
.step-14 .cloud_box p {
  font-size: 14px;
  font-weight: 600;
  color: #1f1f1f;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 40px;
}
.step-14 .done-image {
  width: 217px;
  height: 302px;
  background-color: #8e6dff;
}

.step-14-footer-button {
  margin-top: 46px;
}

.download-button {
  display: inline-block;
  width: 200px;
  height: 40px;
  margin-top: 190px;
  padding: 5px 0px;
  background-color: #000;
  color: #fff;
  text-decoration: none;
  border-radius: 40px;
  font-weight: bold;
  line-height: 27px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}
.home-button {
  display: block;
  margin: 12px 0;
  padding: 10px;
  background-color: #fff;
  color: #aeaeae;
  text-decoration: underline;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}
