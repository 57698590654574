.transparent-loading-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.loading-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-spinner-wrapper {
  max-height: 80px;
  margin-bottom: 20px;
}

.loading-content {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  color: #fff;
}
