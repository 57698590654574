.footer {
  background-color: #fff;
  color: #181818;
  padding: 40px 32px;
  text-align: left;
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-logo {
  margin-bottom: 20px;
  height: 15px;
}

.footer-content p {
  margin: 0 0 23px;
  font-size: 12px;
  line-height: 28px;
}

.footer-content address {
  font-style: normal;
  font-size: 12px;
  line-height: 30px;
  color: #a2a2a2;
}

.footer-links {
  margin-top: 28px;
}
.footer-links a {
  display: block;
  color: #a2a2a2;
  margin-bottom: 10px;
  font-size: 12px;
}

.footer-links a:hover {
  text-decoration: underline;
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-left: -6px;
  margin-bottom: 23px;
}
.social-icon {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-right: -6px;
}
